import React from 'react'
import {MovieListStyle} from './ComponentStyles'
import Pagination from './Pagination'
import ListPoster from './ListPoster'

const MovieList = (props) => {

  const movies = props.movies.map(movie => {
    return (<ListPoster key={movie.id} movie={movie} />)
  })

  var pagination = false

  if(props.pagination && props.numberOfPages !== '1'){
    pagination = <Pagination
      currentPage={parseInt(props.currentPage)}
      numberOfPages={parseInt(props.numberOfPages)}
      onPaginationClick={props.onPaginationClick}
      />
  }


  return(
    <>
    <MovieListStyle>
      {movies}
    </MovieListStyle>
    {pagination}
    </>
  )
}
export default MovieList
