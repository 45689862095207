import React, {Component} from 'react'
import SuggestForm from './SuggestForm'
import styled from 'styled-components'

const SingleResult = styled.article`
  padding: 12px;
  display: flex;
  figure{
    padding-right: 12px;
  }
  h2{
    margin-top: 0;
  }
`

class ResultsListApi extends Component {

  preview = (index, youtube_id) => {
    this.props.onSubmitPreview(index, youtube_id);
  }

  render () {
    if(this.props.list === false){
      return (<></>)
    }else if(this.props.list.length === 0){
      return(
        <section className="resultList">
          <p>No results</p>
        </section>
      )
    }else{
      const {results, total_pages, total_results} = this.props.list
      return(
        <section className="resultList">
          {results.map((movie, i) =>
            <SingleResult key={movie.id}>
              <figure className="image">
                <img src={`https://image.tmdb.org/t/p/w92${movie.poster_path}`} alt={`${movie.title} poster`} />
              </figure>
              <section className="info">
                <h2>{movie.title}</h2>
                <p>{movie.overview}</p>
                <SuggestForm index={i} onPreview={this.preview} />
              </section>
            </SingleResult>
          )}
          {total_pages > 1 && <p>{total_results} movies were found, try narrowing your query if you didn't find what you're looking for.</p>}
        </section>
      )
    }
  }
}

export default ResultsListApi;
