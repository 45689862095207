import React, { Component } from 'react'
import Axios from 'axios'

import { Range } from 'react-range'
import queryString from 'query-string'

import MovieList from '../../components/MovieList'
import LoadingScreen from '../../components/LoadingScreen'

import {GenreListStyle, YearLabelStyle} from './FilterTemplateStyle'


var timer = false;

const minYear = 1950
const maxYear = new Date().getFullYear()

class FilterTemplate extends Component {

  state = {
    showfilter: false,
    years: [
      minYear,
      maxYear
    ],
    genres: [],
    selectedGenres: [],
    movies: false,
    currentPage: 1,
    fetching: true
  }

  componentDidMount(){
    this.getGenres()
    const values = queryString.parse(this.props.location.search);
    let showFilter = Object.keys(values).length === 0 ? false : true
    const firstYear = values.firstyear ? values.firstyear : minYear
    const lastYear = values.lastyear ? values.lastyear : maxYear
    const page = values.p ? values.p : 1
    this.getFilteredMovies(this.state.selectedGenres, [firstYear, lastYear], page)
    this.setState({
      years: [firstYear, lastYear],
      currentPage: page,
      showFilter
    })
  }

  // On mount
  getGenres = () => {
    Axios.get(`${process.env.REACT_APP_API_BASE}/wp/v2/genre?per_page=100`).then(response => {
      const genres = response.data
      this.setState({
        genres
      })
    })
  }


  onFilter = () =>{
    this.setUrlParameters({firstyear: this.state.years[0], lastyear: this.state.years[1],genres: this.state.selectedGenres, p: 1})
    this.getFilteredMovies(this.state.selectedGenres, this.state.years)
  }

  toggleFilter = () => {
    let showFilter = !this.state.showFilter
    this.setState({
      showFilter
    })
  }

  resetFilter = () => {
    this.setUrlParameters({})
    this.getFilteredMovies()
    this.setState({
      years: [minYear, maxYear],
      selectedGenres: []
    })
  }

  updateYear = (array) => {
    this.setState({
      fetching: true
    })
    if(timer){
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      this.onFilter()
    }, 1500)

    this.setState({
      years: array
    })
  }


  getFilteredMovies = (genreArray = [], yearArray = [minYear, maxYear], page = 1) => {

    Axios.get(`${process.env.REACT_APP_API_BASE}/beemoz/v1/filter?years=${yearArray.join()}&genres=${genreArray.join()}&p=${page}`).then(response => {
      this.setState({
        movies: response.data,
        currentPage: page,
        fetching: false,
      })
    })
  }


  addGenreToFilter = (id) => {
    const selectedGenres = this.state.selectedGenres.concat(id)

    this.setState({
      selectedGenres
    })

    this.setUrlParameters({firstyear: this.state.years[0], lastyear: this.state.years[1],genres: selectedGenres, p: 1})
    this.getFilteredMovies(selectedGenres, this.state.years)

  }

  removeGenreFromFilter = (id) => {
    const selectedGenres = this.state.selectedGenres.filter(item => {
      if(item !== id){
        return item
      }else{
        return false
      }
    })

    this.setState({
      selectedGenres
    })

    this.setUrlParameters({firstyear: this.state.years[0], lastyear: this.state.years[1],genres: selectedGenres, p: 1})
    this.getFilteredMovies(selectedGenres, this.state.years)
  }

  setUrlParameters = (parameterObject) => {
    const { p, firstyear, lastyear } = parameterObject
    if(p === 1){
      delete parameterObject.p
    }
    if(firstyear === minYear){
      delete parameterObject.firstyear
    }
    if(lastyear === maxYear){
      delete parameterObject.lastyear
    }

    const parameters = queryString.stringify(parameterObject)
    this.props.history.push({search: parameters})
  }

  // Pagination
  goToPage = (pageNumber) => {
    this.setUrlParameters({firstyear: this.state.years[0], lastyear: this.state.years[1], genres: this.state.selectedGenres, p: pageNumber})
    const genres = this.state.selectedGenres ? this.state.selectedGenres : this.state.genres
    this.getFilteredMovies(genres, this.state.years, pageNumber)
  }

  // Render
  render(){
    const genres = (
      <GenreListStyle>
        {this.state.genres.map(genre => {
          if(genre.count === 0){
            return false
          }

          const isActive = this.state.selectedGenres.includes(genre.slug)
          const onClickFunction = isActive ? this.removeGenreFromFilter : this.addGenreToFilter
          let className = isActive ? 'active' : 'inactive'

          return (<li key={genre.slug} className={className} onClick={() => onClickFunction(genre.slug)}>{genre.name}</li>)
        })}
      </GenreListStyle>
    )

    const yearRange = (<Range
      min={minYear}
      max={maxYear}
      values={this.state.years}
      onChange={this.updateYear}
      renderTrack={({ props, children }) => (
       <div
         {...props}
         style={{
           ...props.style,
           height: '6px',
           width: '100%',
           backgroundColor: '#eee'
         }}
       >
         {children}
       </div>
     )}
     renderThumb={({ props }) => (
      <div
        {...props}
        style={{
          ...props.style,
          height: '28px',
          width: '28px',
          borderRadius: '13px',
          backgroundColor: '#999'
        }}
      />
    )}
    />)

    return (
      <>
        <h1>Movies</h1>
        <h2 onClick={this.toggleFilter}><i className={this.state.showFilter ? 'fa fa-caret-down' : 'fa fa-caret-right'}></i> Filter</h2>
        {this.state.showFilter &&
        <aside>
          <section>
            <h2>Years</h2>
            {yearRange}
            <YearLabelStyle>
              <p>From: {this.state.years[0]}</p>
              <p>To: {this.state.years[1]}</p>
            </YearLabelStyle>
          </section>
          <section>
            <h2>Genres</h2>
            {genres}
          </section>
          <h3>{this.state.movies.total_movies} movies found matching your criteria.</h3>
          <button onClick={this.resetFilter}>Reset filter</button>
        </aside>}
        <section className="filterResults">
          { this.state.fetching ? <LoadingScreen /> : <>



          <MovieList
            movies={this.state.movies.movies}
            pagination
            currentPage={this.state.currentPage}
            numberOfPages={this.state.movies.totalpages}
            onPaginationClick={this.goToPage}
          /></>}
        </section>
      </>
    )
  }
}

export default FilterTemplate
