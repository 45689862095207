// Packages
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Helmet from 'react-helmet'
import ReactGA from 'react-ga'

//templates
import Frontpage from './templates/Frontpage/Frontpage'
import WatchTemplate from './templates/WatchTemplate/WatchTemplate'
import SuggestTemplate from './templates/SuggestTemplate/SuggestTemplate'
import GenreTemplate from './templates/GenreTemplate/GenreTemplate'
import WatchListTemplate from './templates/WatchListTemplate/WatchListTemplate'
import SearchResults from './templates/SearchResults/SearchResults'
import InfoPageTemplate from './templates/InfoPageTemplate/InfoPageTemplate'
import FilterTemplate from './templates/FilterTemplate/FilterTemplate'
//components
import SiteHeader from './components/SiteHeader'
import SiteFooter from './components/SiteFooter'
//style
import './App.scss'

const trackingId = "UA-162958468-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {

  state = {
    menuOpen: false
  }

  useEffect = () => {
    // This line will trigger on a route change
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  toggleMenu = () => {
    const menuOpen = !this.state.menuOpen

    this.setState({
      menuOpen
    })
  }

  render(){
    return (
      <div className={"App" + (this.state.menuOpen ? " menuOpen" : "")}>
        <div className="overlay" onClick={this.toggleMenu}></div>
        <Helmet>
          <title>Beemoz</title>
          <meta name="description" content="Watch B-movies online" />
        </Helmet>
        <Router>
          <SiteHeader onMenuButtonClick={this.toggleMenu} menuOpen={this.state.menuOpen} />
          <section className="Main">
            <Switch>
              <Route exact path="/" component={Frontpage} />
              <Route path="/watch/:slug" component={WatchTemplate} />
              <Route path="/suggest" component={SuggestTemplate} />
              <Route path="/genre/:slug" component={GenreTemplate} />
              <Route path="/watchlist" component={WatchListTemplate} />
              <Route path="/search" component={SearchResults} />
              <Route path="/movies" component={FilterTemplate} />
              <Route path="/info/:slug" component={InfoPageTemplate} />
            </Switch>
          </section>
          <SiteFooter />
        </Router>
      </div>
    );
  }
}

export default App;
