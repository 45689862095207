import React from 'react';
import { Link } from 'react-router-dom';
import WatchListButton from './WatchListButton';
import { ListPosterStyle } from './ComponentStyles';
import vhs from '../img/vhs.jpg'

const ListPoster = (props) => {
  const movie = props.movie;

  const posterPath = "https://image.tmdb.org/t/p/w185"
  var title = ""
  if(typeof movie.title === "object" ){
    title = movie.title.rendered
  }else{
    title = movie.post_title
  }

  let poster = posterPath+movie.acf.poster
  console.log(poster)
  if(!movie.acf.poster){
    poster = vhs
  }
  

  return(
    <ListPosterStyle>
      <Link to={'/watch/'+movie.slug}>
        <img src={poster} alt={title} />
        <h2 dangerouslySetInnerHTML={ {__html: `${title} (${movie.acf.year})`}} />
      </Link>
      <WatchListButton movieId={movie.id} />
    </ListPosterStyle>
  )
}

export default ListPoster
