import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import Axios from 'axios'
import SearchForm from './SearchForm'

class SiteHeader extends Component{

  onSearch = (query) => {
    this.props.history.push(`/search?q=${query}`)
  }

  toggleMenu = (e) => {
    e.preventDefault()

    this.props.onMenuButtonClick()
  }

  menuClick = (route) => {
    this.props.onMenuButtonClick();
    this.props.history.push(route)
  }

  randomMovie = () => {
    Axios.get(`${process.env.REACT_APP_API_BASE}/beemoz/v1/random`).then(response =>{
      console.log(response)
      const slug = response.data[0].post_name
      this.props.history.push(`/watch/${slug}`)
    });
  }

  render(){
    return(
      <header className="App-header">
        <NavLink className="homelink" to="/"><h1>Beemoz</h1><aside>Beta</aside></NavLink>
        <button className="menuButton" onClick={this.toggleMenu}>{this.props.menuOpen ? <i className="fa fa-times"></i> : <i className="fa fa-bars"></i> }</button>
        <nav className="mainMenu">
          {!this.props.location.pathname.match('/search') && <SearchForm placeholder="Search …" onSearch={this.onSearch} />}
          <ul className="headerMenu">
            <li><button className="menuLink" onClick={() => this.menuClick("/movies")}>Movies</button></li>
            <li><button className="menuLink" onClick={() => this.menuClick("/watchlist")}>My Watchlist</button></li>
            <li><button className="menuLink" onClick={this.randomMovie}>Random</button></li>
            <li><button className="menuLink" onClick={() => this.menuClick("/suggest")} >Suggest new</button></li>
          </ul>
        </nav>
      </header>
    )
  }
}

export default withRouter(SiteHeader)
