import React, { Component } from 'react'
import ResultsListApi from '../../components/ResultsListApi'
import PreviewSuggestion from '../../components/PreviewSuggestion'
import SearchForm from '../../components/SearchForm'
import Helmet from 'react-helmet'
import Axios from 'axios'

class SuggestTemplate extends Component {
  state = {
    searchQuery: '',
    searchResults: false,
    preview: false,
    message: false
  }

  updateSearchQuery = (e) => {
    this.setState({
      searchQuery: e.target.value
    })
  }

  searchTheApi = (query) => {

    Axios.get('https://api.themoviedb.org/3/search/movie?api_key=5814877b242a056b5f77741143f59844&query='+query).then(response => {
      console.log(response);
      this.setState({
        searchResults: response.data,
        message: false
      })
    }).catch(response => console.log('Something went wrong', response))
  }

  submissionPreview = (indexInSearchResultsArray, youtube_id) => {
    const movie = this.state.searchResults.results[indexInSearchResultsArray].id;

    Axios.get(`${process.env.REACT_APP_API_BASE}/beemoz/v1/movie_by_id?movie_id=${movie}`).then(response => {
      if(response.data === false){
        Axios.get(`https://api.themoviedb.org/3/movie/${movie}?api_key=5814877b242a056b5f77741143f59844`).then(response => {
          this.setState({
            preview: {
              movieInfo: response.data,
              ytid: youtube_id,
              alreadyExists: false
            }
          })
        })
      }else{
        this.setState({
          preview: {
            movieInfo: response.data,
            ytid: youtube_id,
            alreadyExists: response.data[0]
          }
        })
      }
    }).catch(error => {
      //console.log(error);
    })
  }

  closePreview = () => {
    this.setState({
      preview: false,
      message: false
    })
  }

  confirmSubmission = () => {
    const { movieInfo } = this.state.preview
    const year = movieInfo.release_date.slice(0,4)
    const data = {
      'title': movieInfo.title,
      'slug': movieInfo.title +'-'+ year,
      'content': movieInfo.overview,
      'youtube_id': this.state.preview.ytid,
      'year': year,
      'tmdb_id': movieInfo.id,
      'poster_path': movieInfo.poster_path,
      'tagline': movieInfo.tagline,
      'imdb_id': movieInfo.imdb_id,
      'genres': movieInfo.genres,
    }

    Axios.post(`${process.env.REACT_APP_API_BASE}/beemoz/v1/add_movie`, data).then(
      response =>{
        this.setState({
          preview: false,
          message: "Thank you for your suggestion!"
        })
      }
    ).catch(error => console.dir(error))
  }

  render(){
    return(
      <>
      <Helmet>
        <title>Suggest Movie | Beemoz</title>
        <meta name="description" content="Watch B-movies online" />
      </Helmet>
      <header className="searchHeader">
        <h1>Add a new movie</h1>
        <p>Search for the movie by title.</p>
        <SearchForm onSearch={this.searchTheApi} placeholder="Search database" />
        {this.state.message && <h3>{this.state.message}</h3>}
      </header>
      <ResultsListApi list={this.state.searchResults} onSubmitPreview={this.submissionPreview} />
      {this.state.preview && <PreviewSuggestion movie={this.state.preview} closePreview={this.closePreview} confirmSubmit={this.confirmSubmission} />}
      </>
    )
  }
}

export default SuggestTemplate
