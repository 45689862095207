import React from 'react';

const Curtain = (props) => {
  const imgPath = "https://image.tmdb.org/t/p/w300"
  const playButtonStyle = {
    position: 'absolute',
    width: '60px',
    height: '60px',
    borderRadius: '48%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '26px',
    padding: '0',
    paddingLeft: '4px',
  }
  return (
    <section className="Curtain" style={{
        background: 'linear-gradient(25deg, #2e022e, #5e0129, #8d0021, #bd0014)',
        position: 'relative',
        height: '0px',
        paddingBottom: '56.25%',
      }}>
      <figure style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '25%',
          transform: 'translate(-50%, -50%)',
        }}>
        <img src={imgPath + props.poster} alt="Poster" style={{
            width: '100%'
          }}/>
        <button
          className="playButton"
          onClick={props.onPlayButtonClick}
          style={playButtonStyle}
          ><i className="fa fa-play"></i></button>
      </figure>
      <h3 style={{
          position: 'absolute',
          bottom: '12px',
          width: '100%',
          padding: '12px',
          textAlign: 'center',
          fontStyle: 'italic'
        }}>{props.tagline}</h3>
    </section>
  )
}
export default Curtain
