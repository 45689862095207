import React from 'react'
import { PaginationStyle } from './ComponentStyles'

const Pagination = (props) => {

    const numberOfPages = props.numberOfPages
    if(numberOfPages <= 1){
      return false;
    }

    const currentPage = props.currentPage
    var pagination = false
    var paginationLinks = [1,2]

    if(numberOfPages === 3){
      paginationLinks = [1,2,3]
    }

    if(numberOfPages === 4){
      if(currentPage === 1 ){
        paginationLinks = [1,2,'…',4]
      }else if(currentPage === 4){
        paginationLinks = [1,'…',3,4]
      }else{
        paginationLinks = [1,2,3,4]
      }
    }
    if(numberOfPages === 5){
      switch(currentPage){
        case 1:
          paginationLinks = [1,2,'…',5]
          break
        case 2:
          paginationLinks = [1,2,3,'…',5]
          break
        case 3:
          paginationLinks = [1,2,3,4,5]
          break
        case 4:
          paginationLinks = [1,'…',3,4,5]
          break
        default:
          paginationLinks = [1,'…',4,5]
          break
      }
    }

    if(numberOfPages > 5){

      const secondToLast = numberOfPages-1
      switch(currentPage){
        case 1:
          paginationLinks = [1,2,'…', numberOfPages]
          break
        case 2:
          if(numberOfPages > 4){
            paginationLinks = [1,2,3, '…', numberOfPages]
          }else{
            paginationLinks = [1,2,3, numberOfPages]
          }
          break
        case 3:
          paginationLinks = [1, 2, 3, '…', numberOfPages]
          break
        case secondToLast-1:
          paginationLinks = [1,'…',currentPage-1,currentPage,secondToLast, numberOfPages]
          break
        case secondToLast:
          paginationLinks = [1, '…', secondToLast - 1 ,secondToLast, numberOfPages]
          break
        case numberOfPages:
          paginationLinks = [1, '…', secondToLast, numberOfPages]
          break
        default:
            paginationLinks = [1,'…', currentPage -1, currentPage, currentPage +1,'…', numberOfPages]
          break
      }
    }

    pagination = paginationLinks.map((link, i) => {
      if(link === currentPage){
        return (<span key={link} className="active">{link}</span>)
      }else if(link === '…'){
        return (<span key={`divider-${i}`} className="divider">{link}</span>)
      }else{
        return (<span key={link} className="clickable" onClick={() => props.onPaginationClick(link)}>{link}</span>)
      }
    })
    var prev = currentPage === 1 ? '' :  <span className="previousPage clickable" onClick={() => props.onPaginationClick(currentPage-1)}>Previous</span>
    var next = currentPage === numberOfPages ? '' :<span className="nextPage clickable" onClick={() => props.onPaginationClick(currentPage+1)}>Next</span>

    return (<PaginationStyle>{prev} {pagination} {next}</PaginationStyle>)
  }



export default Pagination
