import React, { Component } from 'react';

class WatchListButton extends Component {

  state = {
    onWatchList: false
  }

  componentDidMount(){
    const watchList = JSON.parse(localStorage.getItem('watchlist'));

    if(watchList){
      if(watchList.includes(this.props.movieId)){
        this.setState({
          onWatchList: true
        })
      }
    }
  }

  toggleWatchListStatus = () => {
    const newStatus = !this.state.onWatchList
    var storageArray = JSON.parse(localStorage.getItem('watchlist'));
    if(!storageArray){
      const createLocalStorage = JSON.stringify([this.props.movieId]);
      localStorage.setItem('watchlist', createLocalStorage);

    }else{
      if(newStatus){
        storageArray.push(this.props.movieId);
        localStorage.setItem('watchlist', JSON.stringify(storageArray));
      }else{
        var newArray = storageArray.filter(movie => {
          return movie !== this.props.movieId
        })
        localStorage.setItem('watchlist', JSON.stringify(newArray));
      }
    }
    this.setState({
      onWatchList: newStatus
    })
  }

  render(){

    const watchListText = this.state.onWatchList ? 'Remove from Watchlist' : 'Add to Watchlist'

    return(
      <button className="WatchListButton" style={{padding: '0 6px', backgroundColor: this.state.onWatchList ? '#ccc' : '#eee'}} onClick={this.toggleWatchListStatus}>{watchListText}</button>
    )
  }
}

export default WatchListButton
