import React, { Component } from 'react'
import Axios from 'axios'
import styled from 'styled-components'

import LoadingScreen from '../../components/LoadingScreen'

const ArticleStyle = styled.article`
  max-width: 700px;
  margin: auto;
`

class InfoPageTemplate extends Component {

  state = {
    loading: true,
    pageInfo: false,
    notFound: false
  }

  componentDidMount(){
    Axios.get(`${process.env.REACT_APP_API_BASE}/wp/v2/pages?slug=${this.props.match.params.slug}`).then(response => {
      if(response.data.length > 0){
        this.setState({
          loading: false,
          pageInfo: response.data[0]
        })
      }else{
        this.setState({
          loading: false,
          notFound: true
        })
      }

    })
  }

  render(){
    if(this.state.loading){
      return(
        <LoadingScreen />
      )
    }else if(this.state.notFound){
      return(
        <ArticleStyle>
          <h1>Not found</h1>
        </ArticleStyle>
      )
    }else{
      return(
        <ArticleStyle>
          <h1 dangerouslySetInnerHTML={{ __html: this.state.pageInfo.title.rendered }} />
          <div dangerouslySetInnerHTML={{ __html: this.state.pageInfo.content.rendered }} />
        </ArticleStyle>
      )
    }

  }
}

export default InfoPageTemplate
