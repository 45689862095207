import React, { Component } from 'react'
import Axios from 'axios'
import Helmet from 'react-helmet'
import queryString from 'query-string'
//components
import LoadingScreen from '../../components/LoadingScreen'
import ListPoster from '../../components/ListPoster'
import SearchForm from '../../components/SearchForm'
//styles
import {SearchResultsHeader} from './SearchResultsStyle'
import {MovieListStyle} from '../../components/ComponentStyles'

class SearchResults extends Component{

  state = {
    loading: true,
    results: false,
    searchError: false,
    searchQuery: ''
  }

  componentDidMount(){
    const values = queryString.parse(this.props.location.search)
    if(values.q){
      this.onSearch(values.q)
    }else{
      this.setState({
        loading: false,
        searchError: 'Please enter a search query.'
      })
    }
  }

  searchFromPage = (query) => {
    this.props.history.push({search: `q=${query}`})
    this.onSearch(query)
  }

  onSearch = (query) => {

    Axios.get(`${process.env.REACT_APP_API_BASE}/wp/v2/posts?search=${query}&post_type=movie`).then(response => {

      this.setState({
        loading: false,
        results: response.data,
        searchError: false,
        searchQuery: query
      })
    })
  }

  render(){
    const values = queryString.parse(this.props.location.search)
    const query = values.q ? values.q : ''
    if(this.state.searchQuery !== query){
      this.onSearch(query);
    }

    const hasResults = this.state.results.length ? true : false;


    if(this.state.loading){
      return <LoadingScreen />
    }else{
      return(
        <>
        <Helmet>
          <title>Search for {query} | Beemoz</title>
        </Helmet>
        <SearchResultsHeader>
          <h1>You searched for {values.q}</h1>
          <SearchForm onSearch={this.searchFromPage} />
        </SearchResultsHeader>
        {this.state.searchError && <h2>{this.state.searchError}</h2>}
        <MovieListStyle>

          {!hasResults && <p>No results.</p> }
          {this.state.results.map(movie => <ListPoster key={movie.id} movie={movie} />)}
        </MovieListStyle>
        </>
      )
    }

  }
}

export default SearchResults
