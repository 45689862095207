import React, {Component} from 'react';

class SearchForm extends Component{

  state = {
    searchResults: false,
    searchQuery: ''
  }
  changeSearchQuery = (e) => {
    this.setState({
      searchQuery: e.target.value
    })
  }

  onSearch = (e) => {
    e.preventDefault()
    if(this.state.searchQuery !== ''){
      this.props.onSearch(this.state.searchQuery)
    }
  }

  render(){
    return (
      <form
        className="search"
        onSubmit={this.onSearch}
      >
        <div className="buttonKeeper" style={{position: 'relative', display: 'flex'}}>
          <input
            type="text"
            autoComplete="off"
            value={this.state.searchQuery}
            onChange={this.changeSearchQuery}
            placeholder={this.props.placeholder}
            name="search"
            style={{width: '100%'}}
          />
          <button
            type="submit"
            style={{
              background: '#111',
              color: '#eee',
              width: '30px',
              height: '30px',
              borderRadius: '14px',
              padding: '0',
              position: 'absolute',
              right: '3px',
              top: '3px'
            }}
          >
            <i className="fas fa-search"></i>
          </button>
        </div>
      </form>
      )
  }
}
export default SearchForm
