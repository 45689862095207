import React, {Component} from 'react'

class SuggestForm extends Component {

  state = {
    ytid: ''
  }

  preview = (e) => {
    e.preventDefault();
    if(this.state.ytid){
      this.props.onPreview(this.props.index, this.state.ytid);
    }
  }

  changeYtid = (e) => {
      this.setState({
        ytid: e.target.value
      })
  }

  render () {
    return(
      <form className="save" onSubmit={this.preview}>
        <input type="text" value={this.state.ytid} onChange={this.changeYtid} name="ytId" placeholder="Youtube ID" style={{marginRight: '12px', marginBottom: '12px'}} />
        <button type="submit">Preview</button>
      </form>
    )
  }
}

export default SuggestForm;
