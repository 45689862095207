import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import MoviePlayer from '../../components/MoviePlayer'
import LoadingScreen from '../../components/LoadingScreen'
import MovieList from '../../components/MovieList'
import ReportForm from '../../components/ReportForm'
import Helmet from 'react-helmet'
import Axios from 'axios'
import { GenreListStyle } from '../../components/ComponentStyles'
import './WatchTemplate.scss'

class WatchTemplate extends Component {
  state = {
    loading: true,
    movieInfo: false,
    genres: false,
    error: false,
    playing: false,
    related: false
  }

  componentDidMount(){
    this.getMovieInfo(this.props.match.params.slug)
  }

  updateMovie = () =>{
    this.getMovieInfo(this.props.match.params.slug)
  }

  getMovieInfo = (slug) => {
    Axios.get(`${process.env.REACT_APP_API_BASE}/wp/v2/movie?slug=${slug}`).then(response =>{
      const movieInfo = response.data[0]

      this.getRelated(movieInfo.id)

      Axios.get(`${process.env.REACT_APP_API_BASE}/wp/v2/genre?post=${movieInfo.id}`).then(genreResponse => {
        this.setState({
          loading: false,
          genres: genreResponse.data,
          movieInfo
        })
      }).catch(error => {console.log('Error fetching genres ', error)})
    }).catch(error => console.log(error))
  }


  getRelated = (movieId) => {
    Axios.get(`${process.env.REACT_APP_API_BASE}/related-posts-by-taxonomy/v1/posts/${movieId}`).then(response => {
      if(response.data.posts.length){
        const sliced = response.data.posts.slice(0,4)
        var ids = sliced.map(movie => {
          return movie.ID;
        })
        Axios.get(`${process.env.REACT_APP_API_BASE}/wp/v2/movie?include=${ids.join()}&orderby=include`).then(response => {
          this.setState({
            related: response.data
          })
        }).catch(error => console.log(error))
      }
    }).catch(error => {
      console.log(error)
    })
  }

  render(){

    const slug = this.props.match.params.slug;

    if(slug !== this.state.movieInfo.slug){
      this.updateMovie()
      return <LoadingScreen />
    }


    if(this.state.loading){
      return(<LoadingScreen />)
    }else{

      const posterPath = "https://image.tmdb.org/t/p/w300"

      const {title, content, acf } = this.state.movieInfo
      const regex = /(<([^>]+)>)/ig;
      const desc = content.rendered.replace(regex, '');
      //console.log(this.state.movieInfo)
      return (
        <>
        <Helmet>
          <title>{title.rendered + ' | Beemoz' }</title>
          <meta name="description" content={acf.tagline} />
          <meta name="og:url" content={window.location} />
          <meta name="og:title" content={title.rendered + ' (' + acf.year + ')'} />
          <meta name="og:description" content={desc} />
          <meta name="og:image" content={posterPath+acf.poster} />

        </Helmet>
        <MoviePlayer movie={this.state.movieInfo} />

        <section className="movieInfo">
          <section className="columns">
            <section className="column synopsis">
              <h1 dangerouslySetInnerHTML={ {__html: title.rendered}} />
              <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
              <p><a href={`https://imdb.com/title/${acf.imdb_id}`} rel="noopener noreferrer" target="imdb">Read more on IMDB</a></p>
            </section>
            <aside className="column facts">
              <h3>Genres</h3>
              <GenreListStyle>
                {this.state.genres.map(genre => (
                  <Link key={genre.slug} to={`/genre/${genre.slug}`}>{genre.name}</Link>
                ))}
              </GenreListStyle>
              <ReportForm movie={this.props.match.params.slug} />
            </aside>
          </section>
        </section>
        { this.state.related &&
          <>
          <h2>Similar movies</h2>
          <MovieList movies={this.state.related} />
          </>
        }

        </>
      )
    }

  }
}

export default WatchTemplate
