import styled from 'styled-components';

const ListPosterStyle = styled.article`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #333;
  border-radius: 13px;
  margin: 6px;
  width: 209px;
  h2{
    font-size: 18px;
  }
  a{
    margin-bottom: auto;
  }
  button{
     margin-top: auto;
  }
  @media all and (max-width: 600px){
    margin: 12px 1%;
    width: 48%;
    img{
      width: 100%;
    }
  }
`;

const GenreListStyle = styled.section`
  display: flex;
  flex-wrap: wrap;
  a{
    display: flex;
    background: #ddd;
    margin-right: 10px;
    margin-bottom: 6px;
    padding: 0 12px;
    height: 36px;
    color: #111;
    border-radius: 17px;
    text-decoration: none;
    line-height: 36px;
  }
`
const MovieListStyle = styled.section`
  display: flex;
  flex-wrap: wrap;

`;

const PreviewModalStyle = styled.div`
  position: fixed;
  top: 100px;
  left: 50%;
  width: 90%;
  max-width: 500px;
  transform: translateX(-50%);
  padding: 12px;
  background: #222;
`;

const PaginationStyle = styled.section`
  display: flex;
  justify-content: center;
  padding: 12px;
  span{
    padding: 4px;
  }
  .active{
    font-weight: bold;
  }
  .divider{

  }
  .clickable{
    text-decoration: underline;
    cursor: pointer;
  }
`;

export { ListPosterStyle, GenreListStyle, MovieListStyle, PreviewModalStyle, PaginationStyle }
