import React, { Component } from 'react'
import Axios from 'axios'
import Helmet from 'react-helmet'
import queryString from 'query-string'

import LoadingScreen from '../../components/LoadingScreen'
import MovieList from '../../components/MovieList'


class GenreTemplate extends Component {

  state = {
    loading: true,
    genreData: false,
    currentPage: false,
    movies: false,
    numberOfPages: 1
  }

  componentDidMount(){
    const values = queryString.parse(this.props.location.search)
    const page = values.p ? values.p : 1
    Axios.get(`${process.env.REACT_APP_API_BASE}/wp/v2/genre?slug=${this.props.match.params.slug}`).then(response => {

      const genre = response.data[0]
      Axios.get(`${process.env.REACT_APP_API_BASE}/wp/v2/movie?genre=${genre.id}&per_page=12&page=${page}`).then(response => {

        const movies = response.data
        this.setState({
          loading: false,
          genreData: genre,
          currentPage: page,
          numberOfPages: response.headers['x-wp-totalpages'],
          movies
        })
      })
    })
  }

  goToPage = (pageNumber) => {
    console.log(this.state)
    if(!this.state.loading){
      Axios.get(`${process.env.REACT_APP_API_BASE}/wp/v2/movie?genre=${this.state.genreData.id}&per_page=12&page=${pageNumber}`).then(response => {

        const movies = response.data
        this.setState({
          loading: false,
          currentPage: pageNumber,
          movies
        })
      })
    }
  }

  render(){
    const values = queryString.parse(this.props.location.search)
    if(values.p && (values.p !== this.state.currentPage)){
      this.goToPage(values.p)
    }

    //const movies = this.state.movies.map(movie => <ListPoster movie={movie} />)
    if(this.state.loading){
      return(
        <LoadingScreen />
      )
    }else{
      return(
        <>
        <Helmet>
          <title>{this.state.genreData.name} | Beemoz</title>
        </Helmet>
        <h1>{this.state.genreData.name}</h1>
        <MovieList
          movies={this.state.movies}
          pagination
          currentPage={this.state.currentPage}
          numberOfPages={this.state.numberOfPages}
          onPaginationClick={this.goToPage}
        />
        </>
      )
    }

  }
}

export default GenreTemplate
