import styled from 'styled-components'

const GenreListStyle = styled.ul`
display: flex;
flex-direction: row;
flex-wrap: wrap;
  li{
    display: flex;
    background-color: #333;
    height: 36px;
    align-items: center;
    padding: 0 12px;
    border-radius: 16px;
    margin-left: 6px;
    margin-bottom: 6px;
    cursor: pointer;
    &.active{
      background-color: #eee;
      color: #111;
      border: 0;
    }
    &.disabled{
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
`

const YearLabelStyle = styled.label`
display: flex;
justify-content: space-between;
margin-top: 20px;
`

export { GenreListStyle, YearLabelStyle }
