import React, { Component } from 'react'
import Axios from 'axios'
import styled from 'styled-components';

const FormStyle = styled.div`
display: flex;
flex-direction: column;
  > *{
    margin-bottom: 10px;
  }
`

class ReportForm extends Component{

  state = {
    showForm: false,
    reason: 'Removed from youtube',
    comment: '',
    message: ''
  }

  updateReason = (e) => {
    this.setState({
      reason: e.target.value
    })
  }

  changeComment = (e) => {
      e.preventDefault()

      this.setState({
        comment: e.target.value
      })
  }

  sendReport = (e) => {
    e.preventDefault()
    let reason = this.state.reason === 'other' ? this.state.comment : this.state.reason

    const data = {
      'comment': reason,
      'slug': this.props.movie
    }
    Axios.put(`${process.env.REACT_APP_API_BASE}/beemoz/v1/report_movie`, data).then(response => {
      this.setState({
        comment: '',
        reason: 'Removed from youtube',
        showForm: false,
        message: 'Thank you for reporting.'
      })
    }).catch(error => {
      this.setState({
        message: 'Something went wrong, please try again.'
      })
    })
  }

  toggleForm = () => {
    const showForm = !this.state.showForm

    this.setState({
      showForm
    })
  }

  render(){

    const text = this.props.text ? this.props.text : "Report movie"

    let isOpen = this.props.open || this.state.showForm;

    const form = (
      <FormStyle className="reportForm">
        <h3>Report movie</h3>
        <label>Reason</label>
        <select name="reason" onChange={this.updateReason}>
          <option value="Removed from youtube" >Removed from youtube</option>
          <option value="Info not correct">Info is not correct</option>
          <option value="Not english">It's not in english</option>
          <option value="other">Other</option>
        </select>

        {this.state.reason === 'other' &&
        <><label>Comment</label>
        <textarea value={this.state.comment} onChange={this.changeComment}></textarea></> }
        <button onClick={this.sendReport}>Report</button>
        {!this.props.open && <p className="clickable" onClick={this.toggleForm}>Hide report form</p>}
      </FormStyle>
    )

    return(
      <>
      {isOpen && form}
      {!isOpen && <span className="clickable" onClick={this.toggleForm}>{text}</span>}
      {this.state.message && <p>{this.state.message}</p>}
      </>
    )
  }
}

export default ReportForm
