import React, { Component } from 'react'
import Axios from 'axios'
import LoadingScreen from '../../components/LoadingScreen'
import MovieList from '../../components/MovieList'

import Helmet from 'react-helmet'

class WatchListTemplate extends Component{
  state = {
    loading: true,
    movies: []
  }

  componentDidMount(){
    const arrayOfIds = JSON.parse(localStorage.getItem('watchlist'));

    if(arrayOfIds && arrayOfIds.length > 0){
      var includeString = '';
      arrayOfIds.forEach(id => {
        includeString += id+','
      })
      Axios.get(`${process.env.REACT_APP_API_BASE}/wp/v2/movie?include=${includeString}`).then(response => {

        this.setState({
          movies: response.data,
          loading: false
        })
      })
    }else{
      this.setState({
        loading: false
      })
    }
  }

  render(){
    if(this.state.loading){
      return(
        <LoadingScreen />
      )
    }else{
      return(
        <>
        <Helmet>
          <title>Watchlist | Beemoz</title>
        </Helmet>
        <h1>Watchlist</h1>
        <section className="movies" style={{display: "flex"}}>
          {this.state.movies.length > 0 ? (<MovieList movies={this.state.movies} />) : (<p>No movies on your watchlist...</p>) }
        </section>
        </>
      )
    }

  }
}

export default WatchListTemplate
