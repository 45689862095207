import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import MoviePlayer from './MoviePlayer'
import ReportForm from './ReportForm'
import {PreviewModalStyle} from './ComponentStyles'

class PreviewSuggestion extends Component {

  state = {
    suggestFormOpen: false
  }

  toggleForm = (e) => {
    const suggestFormOpen = !this.state.reportFormOpen
    this.setState({
      suggestFormOpen
    })
  }

  render(){
    const closeButton = <button className="closeButton" style={{width: '36px', padding: '0', position: 'absolute', top: '-14px', right: '-14px', zIndex: '5'}} onClick={this.props.closePreview}><i className="fa fa-times"></i></button>

    if(this.props.movie.alreadyExists){
      const {post_title, post_name} = this.props.movie.alreadyExists
      return(
        <PreviewModalStyle>
          {closeButton}
          <h2>{post_title} already exists in our database</h2>
          <p><Link to={`/watch/${post_name}`}>Go watch it</Link></p>
          <p>If the current version doesn't work or there seems to be something else wrong, you can report it.</p>
          <ReportForm open />
        </PreviewModalStyle>
      )
    }else{
      return (
        <PreviewModalStyle>
          {closeButton}
          <MoviePlayer ytid={this.props.movie.ytid} />
          <h2>{this.props.movie.movieInfo.title} ({this.props.movie.movieInfo.release_date.slice(0,4)})</h2>
          <p>Does this seem correct?</p>
          <button onClick={this.props.confirmSubmit}>Submit</button>
        </PreviewModalStyle>
      )
    }
  }
}

export default PreviewSuggestion
