import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

const MenuStyle = styled.nav`

`

const menu = [
  {
    title: 'About Beemoz',
    link: '/info/about'
  }
]

const SiteFooter = (props) => {
  return(
  <footer className="siteFooter">
    <MenuStyle>
      <ul>
        {menu.map(link => {
          return <li key={link.title}><Link to={link.link}>{link.title}</Link></li>
        })}
      </ul>
    </MenuStyle>
  </footer>)
}
 export default SiteFooter
