import React, {Component} from 'react'

import Curtain from './Curtain'

class MoviePlayer extends Component {

  state = {
    started: false
  }

  play = () =>{ 
    this.setState({
      started: true
    })
  }

  render(){
    let url = ''
    let acf = false
    if(this.props.movie){
      acf = this.props.movie.acf
      url = `https://www.youtube.com/embed/${acf.youtube_id}?autoplay=1`
    }else{
      url = `https://www.youtube.com/embed/${this.props.ytid}`
    }

    const videoPlayer = (
      <div className="videoWrapper">
        <iframe
        title="Youtube"
        src={url}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        { ... !this.props.ytid && 'autoPlay="1"'}
        ></iframe>
      </div>
    )

    if(this.props.ytid){
      return (
        videoPlayer
      )
    }else{
      if(this.state.started){
        return (
          videoPlayer
        )
      }else{
        return (<Curtain poster={acf.poster} tagline={acf.tagline} onPlayButtonClick={this.play} />)
      }
    }
  }

}

export default MoviePlayer
