import React, { Component } from 'react';
import Axios from 'axios';
import Helmet from 'react-helmet';
import LoadingScreen from '../../components/LoadingScreen';
import MovieList from '../../components/MovieList'

class Frontpage extends Component {

  state = {
    loading: true,
    movies: false,
    genres: false
  }

  componentDidMount(){
    Axios.get(`${process.env.REACT_APP_API_BASE}/wp/v2/movie?per_page=12`).then(response =>
      {
        var loading = true;
        if(this.state.genres){
          loading = false;
        }
        this.setState({
          loading,
          movies: response.data
        })
      }
    ).catch(error => {
      console.log('BAD', error)
    })

    Axios.get(`${process.env.REACT_APP_API_BASE}/wp/v2/genre?per_page=100`).then(response =>
      {
        var loading = true;
        if(this.state.movies){
          loading = false;
        }
        this.setState({
          loading,
          genres: response.data
        })
      }
    ).catch(error => {
      console.log('BAD', error)
    })
  }

  render(){



    if(this.state.loading){
      return(<LoadingScreen />)
    }else{
      return (
        <>
        <Helmet>
          <title>Home | Beemoz</title>
          <meta name="description" content="Watch b-movies online" />
        </Helmet>
        <h2>Latest additions</h2>
        <MovieList movies={this.state.movies} />
        </>
      )
    }
  }
}

export default Frontpage
